<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1A1A1A ;
  font-family: 'PingFangMedium';
}
*{
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
}
h4{
  font-size: 14px;
}
.isActive {
  color: #39f;
}
img{
  vertical-align: middle;
}    
ul,li {
  list-style-type: none;
}
P{
  margin:0;
  padding:0;
}
.right {
  float: right;
}
.left {
  float: left;
}
.clearfix:after{/*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  /* height: 0; */
  clear:both;
  visibility: hidden;
}
.clearfix{
  zoom: 1;/*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}
/* .header{
  width: 100%;
  height: 210px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 10;
} */

.footerWeb{
    background: #282828 ;
    color:#666666;
    font-size: 16px;
    text-align: left;
    padding:28px 0;
}
.navbar{
  background: #fff;
  color: #666666;
  padding: 0.5rem 75px!important;
}
@media screen and (max-width: 768px) {
  .navbar { 
    padding: 0.5rem 30px!important;
  }
}
.official_web .router-link-active li{
  color:#fff;
  background: #12B7A4;
}
.official_web .router-link-active li:hover{
  color: #fff;
  background: #12B7A4;
}
.official_web a{
  color: #666666;
  text-decoration:none;
  display: block;
}
.official_web a:hover {
  color: #666666;
}
</style>
<style>

.navbar-light .navbar-nav .nav-link {
  color: #fff!important;
}
.navbar-light .navbar-nav .nav-link:hover {
  text-decoration:none;
}
.navbar-light .navbar-brand{
  color: #fff!important;
}
.navbar-light .navbar-toggler{
  color: #fff!important;
  border-color:#fff!important;
}
.navbar-toggler-icon{
  background-color:#fff!important;
}
.official_web .dropdown-item{
  color:#000;
}
.official_web .dropdown-item:hover{
  color:#000;
}
</style>
