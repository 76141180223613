import Vue from 'vue'
import Vuex from 'vuex'
import store from './vuex/store.js';
Vue.use(Vuex)
import App from './App.vue'
import router from './router/index'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import ElementUI from 'element-ui';
//样式文件需要单独引入
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import 'jquery'
import 'popper.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './common/font/font.css'
import './common/font/fontBold.css'

Vue.config.productionTip = false
Vue.prototype.$store = store;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
